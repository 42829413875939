<template>
  <div>
    <!-- 全部对战 -->
    <div class="all d-flex">
      <!-- 箱子包含饰品 -->
      <div class="gunlist" v-show="isshow">
        <div class="close" @click="close"></div>
        <div class="box d-flex j-a">
          <div class="boxs d-flex j-a">
            <div
              class="gunarr"
              v-for="(item, index) in gunlist"
              :key="index"
              :class="`weapon-${item.color}`"
            >
              <img class="gun" :src="item.img" alt />
              <div
                class="name color-white"
                style="
                  width: 82px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ item.name }}
              </div>
              <div class="money d-flex j-a">
                <div class="d-flex">
                  <div style="margin-right;5px;">
                    <img class src="@/assets/roll/w-money.png" alt />
                  </div>
                  <div class="color-g">{{ item.payPrice }}</div>
                </div>
                <div class="color-white">{{ item.showProbability }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 背景遮罩 -->
      <div class="bg" v-show="isshow"></div>
      <div class="pkItem" v-for="(item, index) in allPK" :key="index">
        <div
          class="top d-flex color-white weight"
          style="justify-content: space-between"
        >
          <div class="d-flex">
            <div class="left" v-show="item.endStatus == 0">等待中</div>
            <div class="loading" v-show="item.endStatus == 0"></div>
            <div v-show="item.endStatus == 1">已结束</div>
          </div>
          <div style="margin-left: -45px">
            <!-- 欧皇 -->
            <div
              v-if="item.type == 3"
              class="iconfont icon-huangguan"
              style="margin-left: -18px; margin-top: -10px; font-size: 30px"
            ></div>
            <!-- 非酋 -->
            <div
              v-if="item.type == 4"
              class="iconfont icon-beijingyinle-tiaopi"
              style="margin-left: -18px; margin-top: -13px; font-size: 35px"
            ></div>
          </div>
          <!-- 占位符 -->
          <div></div>
        </div>
        <!-- 双人对战 -->
        <div class="main dl" v-show="item.joinType === '1'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-show="item.userList.length == 1"></div>
        </div>
        <!-- 三人对战 -->
        <div class="main dl" v-show="item.joinType === '2'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'i'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div
            class="user"
            v-for="(item, index) in three"
            :key="index + 2"
          ></div>
        </div>
        <!-- 四人对战 -->
        <div class="main dl" v-show="item.joinType === '3'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'ne'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-for="(item, index) in four" :key="index"></div>
        </div>
        <div class="round t-c color-white">{{ item.boxCount }}回合</div>
        <div class="money color-white t-c">
          <img
            src="../../../assets/images/pk/wmoney.png"
            style="width: 20px"
            alt
          />
          {{ item.payCoin }}
        </div>
        <div class="gifts">
          <div
            class="sw"
            v-for="(item, index) in item.boxImgs"
            :key="index + 'new'"
          >
            <img :src="item.img" alt />
          </div>
        </div>
        <div
          class="zt jion f-20"
          v-show="item.endStatus == 0"
          @click="joinPk(item.id)"
        >
          立即加入
        </div>
        <div
          class="zt jion f-20"
          v-show="item.endStatus == 1"
          @click="joinPk(item.id)"
        >
          观战
        </div>
      </div>
      <div class="pkItem" v-for="(item, index) in pklog" :key="index">
        <div
          class="top d-flex color-white weight"
          style="justify-content: space-between"
        >
          <div class="d-flex">
            <div class="left" v-show="item.endStatus == 0">等待中</div>
            <div class="loading" v-show="item.endStatus == 0"></div>
            <div v-show="item.endStatus == 1">已结束</div>
          </div>
          <div style="margin-left: -45px">
            <!-- 欧皇 -->
            <div
              v-if="item.type == 3"
              class="iconfont icon-huangguan"
              style="margin-left: -18px; margin-top: -10px; font-size: 30px"
            ></div>
            <!-- 非酋 -->
            <div
              v-if="item.type == 4"
              class="iconfont icon-beijingyinle-tiaopi"
              style="margin-left: -18px; margin-top: -13px; font-size: 35px"
            ></div>
          </div>
          <!-- 占位符 -->
          <div></div>
        </div>
        <!-- 双人对战 -->
        <div class="main dl" v-show="item.joinType === '1'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-show="item.userList.length == 1"></div>
        </div>
        <!-- 三人对战 -->
        <div class="main dl" v-show="item.joinType === '2'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'i'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div
            class="user"
            v-for="(item, index) in three"
            :key="index + 2"
          ></div>
        </div>
        <!-- 四人对战 -->
        <div class="main dl" v-show="item.joinType === '3'">
          <div
            class="players"
            v-for="(item, index) in item.userList"
            :key="index + 'ne'"
          >
            <img
              v-if="item.portrait == null"
              src="@/assets/images/user/head.jpg"
              alt
            />
            <img v-else :src="item.portrait" alt />
          </div>
          <div class="user" v-for="(item, index) in four" :key="index"></div>
        </div>
        <div class="round t-c color-white">{{ item.boxCount }}回合</div>
        <div class="money color-white t-c">
          <img
            src="../../../assets/images/pk/wmoney.png"
            style="width: 20px"
            alt
          />
          {{ item.payCoin }}
        </div>
        <div class="gifts">
          <div
            class="sw"
            v-for="(item, index) in item.boxImgs"
            :key="index + 'new'"
          >
            <img :src="item.img" alt />
          </div>
        </div>
        <div
          class="zt jion f-20"
          v-show="item.endStatus == 0"
          @click="joinPk(item.id)"
        >
          立即加入
        </div>
        <div
          class="zt jion f-20"
          v-show="item.endStatus == 1"
          @click="joinPk(item.id)"
        >
          观战
        </div>
      </div>
      <div class="pk-al">
        <div class="pk-h">
          <div :class="active == 1 ? 'active' : ''" @click="all">
            全 部 对 战
          </div>
          <div>擂 台 赛</div>
          <div :class="active == 2 ? 'active' : ''" @click="record">
            历 史 记 录
          </div>
        </div>
        <div class="pk-hm">
          <div
            class="pk-hmi"
            v-for="(item, index) in box"
            :key="index + 'mn'"
            @click="look(item.id)"
          >
            <img :src="item.img" alt />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="paixu">
          <div class="paile" @click="paixu">
            <div v-show="xu == 0">综合</div>
            <div v-show="xu == 1">从低到高</div>
            <div v-show="xu == 2">从高到低</div>
            <img src="@/assets/images/pk/paixu.png" alt />
          </div>
          <div class="pairi" @click="$router.push({ name: 'create' })">
            <img src="@/assets/images/pk/jingji.png" alt />
            <div>创建对战</div>
          </div>
        </div>
        <div class="pk-m">
          <div
            class="pk-mi"
            v-for="(item, index) in allPK"
            :key="index"
            :class="
              item.payCoin >= 200
                ? 'gold'
                : item.payCoin >= 100 && item.payCoin < 200
                ? 'red'
                : item.payCoin >= 20 && item.payCoin < 100
                ? 'purple'
                : 'blue'
            "
          >
            <div class="mi-h">
              <div class="mi-hl">
                <div v-show="item.endStatus == 0">等待中</div>
                <div v-show="item.endStatus == 1">已结束</div>
              </div>
              <!-- 欧皇 -->
              <div
                v-if="item.type == 3"
                class="iconfont icon-huangguan"
                style="margin-left: -18px; margin-top: -12px; font-size: 30px"
              ></div>
              <!-- 非酋 -->
              <div
                v-if="item.type == 4"
                class="iconfont icon-beijingyinle-tiaopi"
                style="margin-left: -18px; margin-top: -13px; font-size: 32px"
              ></div>
              <div class="mi-hr">{{ item.boxCount }}回合</div>
            </div>
            <div class="mi-ing">
              <img src="@/assets/images/pk/jinbi.png" alt />
              <div>{{ item.payCoin }}</div>
            </div>
            <!-- 双人对战 -->
            <div class="mi-m" v-show="item.joinType === '1'">
              <div
                class="players"
                v-for="(item, index) in item.userList"
                :key="index"
              >
                <img
                  v-if="item.portrait == null"
                  src="@/assets/images/user/head.jpg"
                  alt
                />
                <img v-else :src="item.portrait" alt />
              </div>
              <div class="user" v-show="item.userList.length == 1"></div>
            </div>
            <!-- 三人对战 -->
            <div class="mi-m" v-show="item.joinType === '2'">
              <div
                class="players"
                v-for="(item, index) in item.userList"
                :key="index + 'i'"
              >
                <img
                  v-if="item.portrait == null"
                  src="@/assets/images/user/head.jpg"
                  alt
                />
                <img v-else :src="item.portrait" alt />
              </div>
              <div
                class="user"
                v-for="(item, index) in three"
                :key="index + 'zxa'"
              ></div>
            </div>
            <!-- 四人对战 -->
            <div class="mi-m" v-show="item.joinType === '3'">
              <div
                class="players"
                v-for="(item, index) in item.userList"
                :key="index + 'iz'"
              >
                <img
                  v-if="item.portrait == null"
                  src="@/assets/images/user/head.jpg"
                  alt
                />
                <img v-else :src="item.portrait" alt />
              </div>
              <div
                class="user"
                v-for="(item, index) in three"
                :key="index + 'zxa'"
              ></div>
            </div>
            <div class="mi-jion">
              <div v-show="item.endStatus == 0" @click="joinPk(item.id)">
                加入战斗
              </div>
              <div v-show="item.endStatus == 1" @click="joinPk(item.id)">
                观看战斗
              </div>
            </div>
            <div class="mi-box">
              <div
                class="mibi"
                v-for="(item, index) in item.boxImgs"
                :key="index + 'w'"
              >
                <img :src="item.img" alt />
              </div>
            </div>
          </div>
          <div
            class="pk-mi"
            v-for="(item, index) in pklog"
            :key="index"
            :class="
              item.payCoin >= 200
                ? 'gold'
                : item.payCoin >= 100 && item.payCoin < 200
                ? 'red'
                : item.payCoin >= 20 && item.payCoin < 100
                ? 'purple'
                : 'blue'
            "
          >
            <div class="mi-h">
              <div class="mi-hl">
                <div v-show="item.endStatus == 0">等待中</div>
                <div v-show="item.endStatus == 1">已结束</div>
              </div>
              <!-- 欧皇 -->
              <div
                v-if="item.type == 3"
                class="iconfont icon-huangguan"
                style="margin-left: -18px; margin-top: -12px; font-size: 30px"
              ></div>
              <!-- 非酋 -->
              <div
                v-if="item.type == 4"
                class="iconfont icon-beijingyinle-tiaopi"
                style="margin-left: -18px; margin-top: -13px; font-size: 32px"
              ></div>
              <div class="mi-hr">{{ item.boxCount }}回合</div>
            </div>
            <div class="mi-ing">
              <img src="@/assets/images/pk/jinbi.png" alt />
              <div>{{ item.payCoin }}</div>
            </div>
            <!-- 双人对战 -->
            <div class="mi-m" v-show="item.joinType === '1'">
              <div
                class="players"
                v-for="(item, index) in item.userList"
                :key="index"
              >
                <img
                  v-if="item.portrait == null"
                  src="@/assets/images/user/head.jpg"
                  alt
                />
                <img v-else :src="item.portrait" alt />
              </div>
              <div class="user" v-show="item.userList.length == 1"></div>
            </div>
            <!-- 三人对战 -->
            <div class="mi-m" v-show="item.joinType === '2'">
              <div
                class="players"
                v-for="(item, index) in item.userList"
                :key="index + 'i'"
              >
                <img
                  v-if="item.portrait == null"
                  src="@/assets/images/user/head.jpg"
                  alt
                />
                <img v-else :src="item.portrait" alt />
              </div>
              <div
                class="user"
                v-for="(item, index) in three"
                :key="index + 'zxa'"
              ></div>
            </div>
            <!-- 四人对战 -->
            <div class="mi-m" v-show="item.joinType === '3'">
              <div
                class="players"
                v-for="(item, index) in item.userList"
                :key="index + 'iz'"
              >
                <img
                  v-if="item.portrait == null"
                  src="@/assets/images/user/head.jpg"
                  alt
                />
                <img v-else :src="item.portrait" alt />
              </div>
              <div
                class="user"
                v-for="(item, index) in three"
                :key="index + 'zxa'"
              ></div>
            </div>
            <div class="mi-jion">
              <div v-show="item.endStatus == 0" @click="joinPk(item.id)">
                加入战斗
              </div>
              <div v-show="item.endStatus == 1" @click="joinPk(item.id)">
                观看战斗
              </div>
            </div>
            <div class="mi-box">
              <div
                class="mibi"
                v-for="(item, index) in item.boxImgs"
                :key="index + 'w'"
              >
                <img :src="item.img" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  searchPkAPI,
  getRoomList,
  getUserInfo,
  pkBoxes,
  boxOrnament,
  getPklog,
} from "../../../api";
import { getToken } from "../../../api/cookie";
import { mapState } from "vuex";
export default {
  data() {
    return {
      allPK: [],
      pklog: [],
      three: [],
      four: [],
      path: this.$route.path,
      userGold: 0,
      show: false,
      active: 1,
      box: [],
      cid: 10,
      sequence: true,
      xu: 0,
      gunlist: [],
      isshow: false,
    };
  },
  computed: {
    ...mapState(["userId"]),
  },
  mounted() {
    let id = "";
    pkBoxes(id).then((res) => {
      this.box = res.data.rows;
    });
    getUserInfo().then((res) => {
      this.userGold = res.data.user.bean;
    });
    searchPkAPI().then((res) => {
      let pk = res.data.data;
      this.allPK = JSON.parse(JSON.stringify(pk.reverse()));
      for (let i = 0; i < this.allPK.length; i++) {
        if (this.allPK[i].userList.length == 1) {
          this.three = ["1", "2"];
          this.four = ["7", "8"];
        } else if (this.allPK[i].userList.length == 2) {
          this.three = ["1"];
          this.four = ["0", "1"];
        } else if (this.allPK[i].userList.length == 3) {
          this.four = ["1"];
        }
      }
    });
    getPklog().then((res) => {
      // console.log(res.data);
      this.pklog = res.data.rows;
      this.pklog.reverse();
    });
  },
  methods: {
    close() {
      this.isshow = false;
    },
    look(id) {
      boxOrnament(id).then((res) => {
        this.gunlist = res.data.rows;
        function compare(property) {
          return function (obj1, obj2) {
            var value1 = obj1[property];
            var value2 = obj2[property];
            return value2 - value1; // 降序
          };
        }
        this.gunlist.sort(compare("openPrice"));
      });
      this.isshow = true;
    },
    all() {
      this.active = 1;
      this.$router.push({ name: "Pk" });
    },
    record() {
      this.active = 2;
      this.$router.push({ name: "pklog" });
    },
    //加入对战
    joinPk(id) {
      let token = getToken();
      if (!token) {
        this.$message("请先登录");
      } else {
        //判断房间满人没
        getRoomList(id).then((res) => {
          let joinType = res.data.data.ttFightAgainst.joinType;
          let roomPeople = res.data.data.users.length;
          //双人房
          if (joinType == "1") {
            //判断房间满人没
            if (roomPeople == 1) {
              //没满，加入对战
              this.$router.push(`/waitepk/${id}`);
            } else {
              //满了，观战
              this.$router.push(`/watch/${id}`);
            }
          }
          //三人房
          else if (joinType == "2") {
            if (roomPeople < 3) {
              //没满
              this.$router.push(`/waitepk/${id}`);
            } else {
              //满了，观战
              this.$router.push(`/watch/${id}`);
            }
          }
          //四人房
          else {
            if (roomPeople < 4) {
              //没满
              this.$router.push(`/waitepk/${id}`);
            } else {
              //满了，观战
              this.$router.push(`/watch/${id}`);
            }
          }
        });
      }
    },
    paixu() {
      function compare(property) {
        return function (obj1, obj2) {
          var value1 = obj1[property];
          var value2 = obj2[property];
          return value1 - value2; // 升序
        };
      }
      function compares(property) {
        return function (obj1, obj2) {
          var value1 = obj1[property];
          var value2 = obj2[property];
          return value2 - value1; // 降序
        };
      }

      if (this.sequence == true) {
        this.xu = 1;
        this.allPK.sort(compare("payCoin"));
        this.sequence = false;
      }
      //升序
      else {
        this.xu = 2;
        this.allPK.sort(compares("payCoin"));
        this.sequence = true;
      }
    },
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to) {
        if (to.name == "allpk") {
          this.at = 1;
          getUserInfo().then((res) => {
            console.log(res);
          });
          searchPkAPI().then((res) => {
            let pk = res.data.data;
            this.allPK = pk.reverse();
            for (let i = 0; i < this.allPK.length; i++) {
              if (this.allPK[i].userList.length == 1) {
                this.three = ["1", "2"];
                this.four = ["0", "1", "2"];
              } else if (this.allPK[i].userList.length == 2) {
                this.three = ["1"];
                this.four = ["0", "1"];
              } else if (this.allPK[i].userList.length == 3) {
                this.four = ["1"];
              }
            }
          });
        } else if (to.name == "pklog") {
          this.at = 2;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
// 背景隐藏层
.bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 90;
  background-color: rgba(0, 0, 3, 0.774);
}
//电脑端
.all {
  width: 1070px;
  overflow: auto;
  margin: 10px auto;
  height: 286px;
  flex-wrap: wrap;
  .gunlist {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    height: 70%;
    background: url("~@/assets/images/pk/bg888.png") no-repeat;
    background-size: 100% 100%;
    overflow: auto;
    z-index: 95;
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 40px;
      height: 40px;
      background: url("~@/assets/images/pk/close.png") no-repeat;
      background-size: 100% 100%;
    }
    .box {
      width: 90%;
      height: 400px;
      overflow: hidden;
      margin: 10px auto;
      padding: 30px 0;
    }
    .boxs {
      width: 90%;
      margin: 10px auto;
      flex-wrap: wrap;
      overflow: auto;
    }
    .gunarr {
      width: 112px;
      height: 142px;
      // background: url("~@/assets/images/pk/bg111.png") no-repeat;
      // background-size: 100% 100%;
      padding: 20px 0;
      box-sizing: border-box;
      margin: 5px 0;
      .gun {
        display: block;
        height: 52px;
        margin: auto;
      }
      .name {
        text-align: center;
        margin: 10px auto;
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        // 没置超出部分显示省略号
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .money {
        font-size: 12px;

        width: 85%;
        margin: 10px auto;
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .pkItem {
    width: 263px;
    height: 286px;
    // padding: 20px 0;
    border-top: 1px transparent;
    background: url("~@/assets/images/pk/pkimg.png") no-repeat;
    background-size: 100% 100%;
    flex: none;

    .top {
      width: 80%;
      margin: 10px auto;

      .loading {
        width: 20px;
        height: 20px;
        background: url("../../../assets/images/pk/loading.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .money img {
      vertical-align: middle;
    }

    .main {
      width: 200px;
      height: 40px;
      margin: auto;
      justify-content: center;

      .user {
        width: 36px;
        height: 36px;
        border: 1px solid white;
        border-radius: 50%;
        flex: none;
      }

      .players {
        width: 36px;
        height: 36px;
        flex: none;

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }

    .round {
      font-weight: none;
      margin: 8px;
    }

    .jion {
      width: 130px;
      height: 35px;
      margin: auto;
      text-align: center;
      line-height: 35px;
      color: #000;
      background: url("~@/assets/images/pk/jion.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 15px;
    }

    .gifts {
      width: 60%;
      height: 43px;
      margin: auto;
      overflow: auto;
      display: flex;
      align-items: center;

      .sw {
        width: 74px;
        height: 39px;
        overflow: hidden;
        margin: auto;

        img {
          width: 74px;
          height: 39px;
        }
      }
    }
  }

  .pk-al {
    display: none;
  }
}

.dl {
  display: flex;
}

//手机端
@media (max-width: 550px) {
  .all {
    display: block;
    width: 100%;
    height: 700px;
    margin-left: 0px;
    overflow: hidden;

    .pkItem {
      display: none;
    }

    .pk-al {
      display: block;
      min-height: 600px;
      height: auto;
      position: relative;
      margin-bottom: 25px;

      .pk-h {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        //background-color: #fff;

        div {
          flex: none;
          text-align: center;
          width: 30%;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
        }

        .active {
          color: rgba(255, 240, 0, 1);
          border-bottom: 2px solid rgba(255, 240, 0, 1);
        }
      }

      .pk-hm {
        width: 96%;
        height: 70px;
        margin: 10px auto 0px;
        overflow: auto;
        display: flex;

        .pk-hmi {
          flex: none;
          width: 60px;
          height: 100%;
          margin-left: 10px;
          text-align: center;
          color: #fff;
          background-image: url("../../../assets/images/pk/bg111.png");
          background-size: 100% 100%;

          img {
            display: block;
            width: 60%;
            height: 50%;
            margin: 4px auto;
          }

          div {
            width: 80%;
            margin: auto;
            font-size: 11px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .paixu {
        width: 90%;
        height: 40px;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;

        .paile {
          width: 90px;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #fff;

          img {
            width: 18px;
            height: 18px;
            margin-left: 8px;
          }
        }

        .pairi {
          width: 100px;
          height: 100%;
          display: flex;
          justify-content: center;
          font-size: 14px;
          line-height: 40px;
          color: #fff;
          background-image: url("../../../assets/images/pk/bg222.png");
          background-size: 100% 100%;

          img {
            margin-top: 12px;
            margin-right: 2px;
            width: 20px;
            height: 20px;
          }
        }
      }

      .pk-m {
        width: 100%;
        height: 520px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .pk-mi {
          width: 45%;
          height: 250px;
          margin-bottom: 4px;
          margin-left: 10px;
          background-size: 100% 100%;

          .mi-h {
            width: 130px;
            height: 20px;
            margin: 12px auto 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .mi-hl {
              width: 60px;
              line-height: 14px;
              font-size: 14px;
              color: #fff;
            }

            .mi-hr {
              font-size: 14px;
              line-height: 14px;
              color: #fff;
            }
          }

          .mi-m {
            width: 100px;
            height: 80px;
            margin: 8px auto 0px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            overflow: hidden;
            //background-color: #fff;

            .players {
              flex: none;
              width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }

            .user {
              flex: none;
              width: 40px;
              height: 40px;
              background-image: url("../../../assets/images/waitpk/join.png");
              background-size: 100% 100%;
            }
          }

          .mi-ing {
            width: 100px;
            height: 20px;
            margin: 4px auto 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(255, 240, 0, 1);

            img {
              flex: none;
              margin-right: 8px;
              height: 80%;
              width: 18px;
            }
          }

          .mi-jion {
            width: 70px;
            height: 30px;
            margin: 20px auto 0px;
            background-image: url("../../../assets/images/pk/jion.png");
            background-size: 100% 100%;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
          }
        }

        .gold {
          background-image: url("../../../assets/images/pk/gold.png");
        }

        .red {
          background-image: url("../../../assets/images/pk/red.png");
        }

        .purple {
          background-image: url("../../../assets/images/pk/purple.png");
        }

        .blue {
          background-image: url("../../../assets/images/pk/blue.png");
        }
      }

      .mi-box {
        width: 84%;
        height: 48px;
        margin: 4px auto 0px;
        display: flex;
        overflow: auto;

        .mibi {
          width: 36%;
          height: 100%;
          flex: none;
          margin-right: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url("../../../assets/images/pk/pm.png");
          background-size: 100% 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .dl {
    display: flex;
  }
}
</style>
